<template>
  <WeContainer header="true" v-bind:actions="actions">
    <WeTable
      v-bind:ajax="true"
      v-bind:index="false"
      v-bind:data="brand.list"
      v-bind:allowSortIndex="false"
      v-bind:columns="columns"
      v-bind:loading="loading"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="true"
      v-bind:fetchOnMount="true"
      v-bind:draggable="true"
      v-bind:table-result="tableResult"
      v-bind:stateName="'brand'"
      v-on:order-changed="onOrderChange"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:FilterLanguage="FilterLanguage"
      v-on:make-request="makeRequest"
    ></WeTable>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      loading: false,
      languageFilter: "tr",
      actions: [
        {
          text: "Yeni Marka",
          path: "brands/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "brands/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "name", th: "Marka Adı", type: "string" },
        { name: "type_name", th: "Marka Tipi", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("brand", ["getList", "delete", "isActive", "orderUpdate"]),
    makeRequest(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;
      this.getList({
        lang: this.languageFilter,
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result.data, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("brands/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.name} adlı markayı silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Marka Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Marka Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    onOrderChange(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.orderUpdate({
          form: data,
          onSuccess: (result) => {
            this.showAlert(result.data.status, "Marka Sırası Güncellendi");
          },
          onError: () => {
            this.showAlert("error", "Marka Sırası Güncellenemedi");
          },
        });
      }
    },
    showAlert(type, message) {
      if (type) {
        switch (type) {
          case "success":
            this.$toast.success(message);
            break;

          default:
            this.$toast.error(message);
            break;
        }
      } else {
        this.showAlert("error", message);
      }
    },
    checkPermission(code) {
      return permission.check("brand", code);
    },
    FilterLanguage(lang) {
      this.languageFilter = lang;
      this.makeRequest();
    },
  },
  computed: {
    ...mapState(["shared", "brand", "session"]),
  },
  mounted() {
    let locales = this.session?.config["site.available_locales"];
    if (locales != undefined) {
      locales = JSON.parse(locales);
      if (locales.length > 1)
        this.columns.push({ name: "locales", th: "Dil", type: "language" });
    }
  },
};
</script>
